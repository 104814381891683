import { Box, Typography } from "@material-ui/core";
import React from "react";
import Hero from "../components/Home/Hero";

const About = () => {
  return (
    <Box>
      <Hero />
      <Box
        sx={{
          px: {
            md: 20,
            xs: 2,
          },
          pt: 6,
        }}
      >
        <Typography
          sx={{
            py: 4,
          }}
          variant="h1"
          align="center"
        >
          ABOUT
        </Typography>
        <Typography variant="body1" align="center">
          D&S National, Inc. helps make national and international, large and
          small businesses, ready to respond to complex and unpredictable
          challenges. With our team of experts at your side, your business will
          become more agile and competitive.
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{
            mt: 1,
          }}
        >
          The best way for us to work for you is to work with you.” The
          Collaborative Business Experience™ helps you define strategy
          development through to implementation, clients benefit from our
          tailored approach. Working beside you every step of the way, we
          analyze your challenges and guide you through your transformation.
        </Typography>
        <Typography
          sx={{
            py: 2,
          }}
          variant="h5"
          align="center"
        >
          WHAT WE OFFER
        </Typography>
        <Typography
          sx={{
            py: 2,
          }}
          variant="h5"
          align="left"
        >
          PRODUCT MANAGEMENT AND DEVELOPMENT{" "}
        </Typography>
        <Typography
          variant="body1"
          align="left"
          sx={{
            mt: 1,
          }}
        >
          D&S is committed to building innovative and agile solutions that help
          you become even more competitive and successful—now and in the future.
        </Typography>
        <Typography
          variant="body1"
          align="left"
          sx={{
            mt: 1,
          }}
        >
          We deliver on that commitment by investing significant resources into
          continually evolving and enhancing our system development life cycle.
          This involves developing new products as part of our solutions, as
          well as delivering new features and capabilities for existing
          customers.
        </Typography>
        <Typography
          sx={{
            py: 2,
          }}
          variant="h5"
          align="left"
        >
          MANAGED SERVICES
        </Typography>
        <Typography
          variant="body1"
          align="left"
          sx={{
            mt: 1,
          }}
        >
          Focus on growing your business, remaining competitive and supporting
          your customers—and leave the management of your Business Support
          Systems infrastructure to D&S.
        </Typography>
        <Typography
          variant="body1"
          align="left"
          sx={{
            mt: 1,
          }}
        >
          With D&S Managed Services, we apply our experience and expertise to
          manage your infrastructure, applications and BSS operations to deliver
          business improvements while controlling costs.
        </Typography>
        <Typography
          sx={{
            py: 2,
          }}
          variant="h5"
          align="left"
        >
          PRODUCT SUPPORT
        </Typography>
        <Typography
          variant="body1"
          align="left"
          sx={{
            mt: 1,
          }}
        >
          D&S believes that the more optimized our solutions are to your
          business, the more value you’ll get from them. Our product support
          teams fulfills a wide range of needs that enable you to fine-tune your
          solution to best suit your needs, answer questions and serve as an
          important resource as you run your business.
        </Typography>
        <Typography
          variant="body1"
          align="left"
          sx={{
            mt: 1,
          }}
        >
          Our support operations fulfill a wide range of needs, including but
          not limited to:
          <ul>
            <li>Technical assistance to the customer contact person.</li>
            <li>On site software development or enhancements.</li>
            <li>24/7 on-call production supports.</li>
            <li>Ad hoc queries and customize reporting.</li>
            <li>Re-creation and proof of problems.</li>
            <li>Diagnosis of problem cause.</li>
            <li>Provision of work-arounds (where possible).</li>
            <li>
              Correction of environmental problems through remote access, where
              applicable.
            </li>
          </ul>
        </Typography>
        <Typography
          sx={{
            py: 2,
          }}
          variant="h5"
          align="left"
        >
          BUSINESS SOLUTIONS IMPLEMENTATION
        </Typography>
        <Typography
          variant="body1"
          align="left"
          sx={{
            mt: 1,
          }}
        >
          D&S brings over 20+ years of experience deploying, developing,
          managing and hosting a wide range of industry-leading revenue
          management and customer management solutions.
        </Typography>
        <Typography
          variant="body1"
          align="left"
          sx={{
            mt: 1,
          }}
        >
          Working with Fortune 500 companies across the world, we have helped
          companies scale from zero customers to millions in rapid time. And we
          know what it means to work on a timeline, a budget and with your goals
          in mind.
        </Typography>
        <Typography
          variant="body1"
          align="left"
          sx={{
            mt: 1,
          }}
        >
          Highly-experienced implementation consultants are the key to ensuring
          you maximize your investment. Combining very detailed product
          knowledge with extensive industry experience, our professionals have
          unmatched ability to design, develop and deliver world-class
          implementation.
        </Typography>
      </Box>
    </Box>
  );
};

export default About;
