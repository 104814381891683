import React from "react";
import Hero from "../components/Home/Hero";
import { Box, Typography } from "@material-ui/core";
import OurServiceCards from "../components/Service/OurServiceCards";
import Methodology from "../components/Service/Methodology";
import Sections from "../components/Service/Sections";

const Services = () => {
  return (
    <>
      <Hero />
      <Box
        sx={{
          px: {
            md: 20,
            xs: 2,
          },
          pt: 6,
        }}
      >
        <Typography variant="h1" align="center">
          Services
        </Typography>
        <OurServiceCards />
        <Methodology />
        <Sections />
      </Box>
    </>
  );
};

export default Services;
