import { Box, LinearProgress, Typography } from "@material-ui/core";
import React from "react";

// Show linear Progress bar with skill names and percentage
const data = [
  {
    id: 1,
    name: "Java, .Net, Flex 4.6, Oracle ADF Software Dev",
    percentage: 95,
    color: "#5cb85c",
  },
  {
    id: 2,
    name: "Product Support",
    percentage: 85,
    color: "#5bc0de",
  },
  {
    id: 3,
    name: "Oracle & SQL Server Administration / Dev.",
    percentage: 75,
    color: "#5cb85c",
  },
  {
    id: 4,
    name: "Project Management",
    percentage: 55,
    color: "#5bc0de",
  },
];

const Skills = () => {
  return (
    <>
      <Typography variant="h4" sx={{ my: 3 }} align="center">
        OUR SKILLS
      </Typography>
      {data.map((item) => (
        <Box key={item.id} sx={{ mb: 2, position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 1,
              position: "absolute",
              width: "100%",
              top: 0,
              zIndex: 1,
            }}
          >
            <Box sx={{ color: "text.light", fontSize: "14px", pl: 1, pt: 0.5 }}>
              {item.name}
            </Box>
            <Box sx={{ color: "text.dark", pt: 0.3, pr: 0.5 }}>
              {item.percentage}%
            </Box>
          </Box>
          <LinearProgress
            variant="determinate"
            value={item.percentage}
            sx={{
              height: 30,
              "& .MuiLinearProgress-bar": {
                backgroundColor: item.color,
              },
            }}
          />
        </Box>
      ))}
    </>
  );
};

export default Skills;
