import { Box } from "@material-ui/core";
import React from "react";
import HeroImg from "../../images/cropped-businessman-w-logo-1.jpg";

const Hero = () => {
  return (
    <>
      <Box
        component="img"
        src={HeroImg}
        alt="DS-AI"
        sx={{
          height: "auto",
          width: "100%",
        }}
      />
    </>
  );
};

export default Hero;
