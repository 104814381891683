import { Box, Container, Link, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const Footer = (props) => (
  <Box
    sx={{
      backgroundColor: "background.dark",
      py: 3,
      mt: 12,
    }}
    {...props}
  >
    <Container maxWidth="lg">
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Typography
          sx={{
            color: "primary.contrastText",
          }}
          variant="caption"
        >
          Copyright © 2023 D&S National Inc.
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Link
          color="primary"
          component={RouterLink}
          to="/privacy"
          variant="caption"
        >
          Privacy Policy
        </Link>
        <Link
          color="primary"
          component={RouterLink}
          to="/terms"
          sx={{ ml: 1 }}
          variant="caption"
        >
          Terms of Use
        </Link>
      </Box>
    </Container>
  </Box>
);

export default Footer;
