import * as React from "react";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import styled from "@emotion/styled";
import { CircleOutlined } from "@material-ui/icons";
import { Box } from "@material-ui/core";

const accordionData = [
  "We deliver projects better!",
  "Project Planners/ Schedulers",
  "Superb Business Analysts",
  "On site Custom Development",
  "End to End Solutions",
  "Creative and Responsive Applications",
  "Database Administration and Architecture",
];

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function WhyChooseUs() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        mt: 6,
      }}
    >
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h5">WHY CHOOSE US</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            ml: 4,
          }}
        >
          {accordionData.map((data) => (
            <Box key={data.id} sx={{ mb: 0.5, display: "flex" }}>
              <CircleOutlined
                sx={{
                  fontSize: 10,
                  display: "flex",
                  alignSelf: "center",
                  mr: 1,
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  color: "text.dark",
                  fontSize: "18px",
                }}
                align="left"
              >
                {data}
              </Typography>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography variant="h5">CERTIFICATION</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            State certification is Florida’s premier stamp of approval for
            Minority, Women, and Florida Veteran business enterprises. It is
            widely accepted across the State of Florida in the private sector as
            well as cities, counties, school districts, hospitals, and other
            governmental entities.
          </Typography>
          <Typography
            sx={{
              mt: 2,
            }}
          >
            The goal of the Office of Supplier Diversity is to increase the
            number of business enterprises in the State of Florida.
            Certification officers are responsible for identifying and
            certifying business enterprises by conducting thorough and effective
            eligibility reviews while ensuring that vendors receive prompt and
            responsive customer service. Eligibility criteria can be found in
            Chapter 287, Florida Statutes, Chapter 295.187, Florida Statues and
            Chapter 60A-9, Florida Administrative Code.
          </Typography>
          <Typography
            sx={{
              mt: 2,
            }}
          >
            Please feel free to contact us if you are interested in using either
            D&S National, Inc. or our Minority Owned Business Project as a
            contractor or subcontractor: (407) 485-3403.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
