import { Box } from "@material-ui/core";
import React from "react";
import Logo from "./Logo";

const TopBar = () => {
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          pb: 3,
          alignItems: "center",
        }}
      >
        <Logo />
      </Box>
    </Box>
  );
};

export default TopBar;
