import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography } from "@material-ui/core";
import Img1 from "../../images/1.jpg";
import Img2 from "../../images/2.png";
import Img3 from "../../images/3.png";
import Img4 from "../../images/4.png";
import Img5 from "../../images/5.png";
import Img6 from "../../images/6.png";
import Img7 from "../../images/7.png";
import Img8 from "../../images/8.png";
import Img9 from "../../images/9.png";
import Img10 from "../../images/10.png";
import Img11 from "../../images/11.png";
import Img12 from "../../images/12.png";
import Img13 from "../../images/13.png";
import Img14 from "../../images/14.png";
import Img15 from "../../images/15.png";

const images = [
  {
    id: 1,
    src: Img1,
    alt: "Img1",
  },
  {
    id: 2,
    src: Img2,
    alt: "Img2",
  },
  {
    id: 3,
    src: Img3,
    alt: "Img3",
  },
  {
    id: 4,
    src: Img4,
    alt: "Img4",
  },
  {
    id: 5,
    src: Img5,
    alt: "Img5",
  },
  {
    id: 6,
    src: Img6,
    alt: "Img6",
  },
  {
    id: 7,
    src: Img7,
    alt: "Img7",
  },
  {
    id: 8,
    src: Img8,
    alt: "Img8",
  },
  {
    id: 9,
    src: Img9,
    alt: "Img9",
  },
  {
    id: 10,
    src: Img10,
    alt: "Img10",
  },
  {
    id: 11,
    src: Img11,
    alt: "Img11",
  },
  {
    id: 12,
    src: Img12,
    alt: "Img12",
  },
  {
    id: 13,
    src: Img13,
    alt: "Img13",
  },
  {
    id: 14,
    src: Img14,
    alt: "Img14",
  },
  {
    id: 15,
    src: Img15,
    alt: "Img15",
  },
];

const OurSoftwares = () => {
  const sliderResponsieSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        my: 6,
      }}
    >
      <Typography variant="h4" sx={{ my: 3 }} align="center">
        SOFTWARE WE USE
      </Typography>
      <Slider {...sliderResponsieSettings}>
        {images.map((image) => (
          <Box key={image.id} sx={{ p: 1 }}>
            <Box
              component="img"
              src={image.src}
              alt={image.alt}
              sx={{
                height: 100,
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default OurSoftwares;
