import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import TSImg from "../../images/ts.jpeg";
import DMSImg from "../../images/dms.jpeg";
const Sections = () => {
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 4,
          py: 8,
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eee",
          }}
        >
          <iframe
            width="520"
            height="293"
            src="https://www.youtube.com/embed/THBX3Coc8OQ"
            title="The Data Center Mural Project: Meet the Artist - Oli-B"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" align="left">
            DEVELOPMENT SERVICES
          </Typography>
          <Typography variant="body1" align="left" sx={{ p: 1 }}>
            D & S National, Inc. develop, verify and provide testing services
            for your embedded engineering systems, devices and software to
            ensure your products and systems deliver the functionality, user
            experience and compliance you need. We cover your complete system
            architecture, including:
            <ul>
              <li>Design</li>
              <li>Coding</li>
              <li>Testing</li>
              <li>Support</li>
            </ul>
          </Typography>
          <Typography variant="body1" align="left" sx={{ p: 1 }}>
            Four Practice Areas: <br />
            Web, Mobile, OS Applications and Databases, we give our clients a
            variety of solutions. While never claiming to be experts in
            everything, we have built experienced teams in four primary areas.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
          <Typography variant="h3" align="left">
            TESTING SERVICES
          </Typography>
          <Typography variant="body1" align="left" sx={{ p: 1 }}>
            Customers expect to connect to businesses anytime, anywhere, on any
            device. More than before, application quality and performance are
            critical to succeed in today’s digital, ‘always-on’ world. Most
            organizations are responding by an increased focus on quality,
            although many testing practices are struggling to adapt to the
            changing demands from digital transformation. This is especially
            true when they spend as much as 12 percent of the annual IT budget
            fixing core application problems. Add in the new realties—social,
            mobility, analytics and cloud—that are fundamentally changing how
            companies do business.
          </Typography>
          <Typography variant="body1" align="left" sx={{ p: 1 }}>
            <br />
            Our decades of experience delivering leading, end-to-end testing
            services have taught us that, by adopting industrialized processes
            and infusing intelligence into delivery, organizations can reduce
            operation costs and improve application stability.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eee",
            mt: 3,
          }}
        >
          <Box
            component="img"
            src={TSImg}
            alt="TSImg"
            sx={{ width: "97%", display: "block", margin: "auto" }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 4,
          py: 8,
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eee",
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d317893.9737282887!2d-0.11951900000000001!3d51.503186!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604b900d26973%3A0x4291f3172409ea92!2slastminute.com%20London%20Eye!5e0!3m2!1sen!2sus!4v1695295589739!5m2!1sen!2sus"
            width="600"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="Google Map"
          ></iframe>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" align="left">
            PROJECT MANAGERS
          </Typography>
          <Typography variant="body1" align="left" sx={{ p: 1 }}>
            D & S National, Inc. provides highly experienced program managers
            and project managers with the leadership capabilities to
            successfully guide, manage, and support high-visibility initiatives.{" "}
            <br />
            We specialize in:
            <ul>
              <li>
                Managing strategic, mission-critical initiatives: Our clients
                utilize our consultants when they can’t afford program or
                project failure – even staffing companies turn to us!
              </li>
              <li>
                Recovering troubled projects: Our consultants are skilled at
                conducting rapid diagnosis of problems, instituting course
                corrections, and bringing projects back on track.
              </li>
              <li>
                Interim leadership: We can take charge of PMOs and similar
                organizations, run them for you for short or long term
                durations, and transition them back to you when you’re ready.
              </li>
              <li>
                Filling skill set gaps: If your people are proficient in some
                areas but not so much in others (e.g., risk management), we can
                provide the expertise you need. We can also help better manage
                key resource dependency issues.
              </li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
          <Typography variant="h3" align="left">
            DATA MANAGEMENT SERVICES
          </Typography>
          <Typography variant="body1" align="left" sx={{ p: 1 }}>
            D & S National, Inc. Database Management service encompasses all
            setup, Data migrations, upgrades, management, and maintenance
            activities for clients’ database servers, and includes OS and
            database application support. Certified Database Administrators
            (DBAs) improve performance through optimized database design,
            tuning, and troubleshooting services. Additionally, this service
            includes proactive monitoring, reporting, and support for
            environments on all OS platforms.
          </Typography>
          <Typography variant="body1" align="left" sx={{ p: 1 }}>
            <br />
            We provide Specialist Support for your data migration activities in
            a structured and cost-effective way and thus mitigate the risks
            faced when moving to and from one version of the database to
            another, cloud or archiving off historical records. The service
            includes a comprehensive data value assessment to determine the
            scope of data sources, metadata, permissions and classification
            requirements before migration to the agreed destination.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#eee",
            mt: 3,
          }}
        >
          <Box
            component="img"
            src={DMSImg}
            alt="DMSImg"
            sx={{ width: "97%", display: "block", margin: "auto" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Sections;
