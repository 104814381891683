import React from "react";
import BGImg from "../../images/contact-bg.jpg";
import { Box, Typography } from "@material-ui/core";

const Methodology = () => {
  return (
    <Box>
      <Box
        // Make a parallex effect with the background image
        sx={{
          backgroundImage: `url(${BGImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          color: "white",
          fontSize: "2rem",
          fontWeight: "bold",
          textShadow: "1px 1px 2px black",
          px: 8,
          py: 14,
          backgroundAttachment: "fixed",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#2424dc70",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            py: 12,
            px: 4,
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            sx={{
              pt: 4,
            }}
          >
            METHODOLOGY
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            align="center"
            sx={{
              pt: 2,
            }}
          >
            A key element in the success of D & S National, Inc. is the proven
            methodology which takes each project through the critical steps from
            inception through operations. Our approach focuses on ensuring that
            projects are properly planned and designed before implementation
            begins, so that the end project minimizes your risk and delivers
            maximum business impact.
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            align="center"
            sx={{
              pt: 2,
            }}
          >
            D & S National’s team of consultants, developers, administrators,
            architects, technical project managers, systems engineers and
            service specialists provide end-to-end integrated business
            solutions. Our service spans the lifecycle of an IT solution from
            assessing, architecting, project planning, to project
            implementation, documentation and ongoing support.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Methodology;
