import React from "react";
import Img1 from "../../images/s1.png";
import Img2 from "../../images/s2.png";
import Img3 from "../../images/s3.png";
import Img4 from "../../images/s4.png";
import Img5 from "../../images/s5.png";
import Img6 from "../../images/s6.png";
import { Box, Grid, Typography } from "@material-ui/core";

const data = [
  {
    id: 1,
    src: Img1,
    alt: "Img1",
    title: "Assess",
    content:
      "The solutions engineering process begins with a comprehensive evaluation of the technical health of your computing environment.",
  },
  {
    id: 2,
    src: Img2,
    alt: "Img2",
    title: "Architect",
    content:
      "During the initial design session we work closely together to co-develop a technology solution based on a solid understanding of unique business requirements.",
  },
  {
    id: 3,
    src: Img3,
    alt: "Img3",
    title: "Implement",
    content:
      "Clients that face a lack of capacity and/or a lack of capability can rely on D & S National’s staff to compliment and supplement their staff.",
  },
  {
    id: 4,
    src: Img4,
    alt: "Img4",
    title: "Technology",
    content:
      "Clients of D & S National, Inc. enjoy having their business and technology needs align with their budgetary and time requirements.",
  },
  {
    id: 5,
    src: Img5,
    alt: "Img5",
    title: "Document",
    content:
      "Proper documentation is an important part of implementing any new IT solution. D & S National’s provides develop systems configuration documentation detailing the important attributes of the information systems, step-by-step standard operating procedures and ongoing administrative services.",
  },
  {
    id: 6,
    src: Img6,
    alt: "Img6",
    title: "Support",
    content:
      "D & S National, Inc. can become your source for on-going service needs. We allow customers to reduce the burden on your staff, shorten your to-do list, and let your IT investment work for you.",
  },
];

const OurServiceCards = () => {
  return (
    <Grid
      container
      spacing={3}
      sx={{
        py: 6,
        flexGrow: 1,
      }}
    >
      {data.map((item) => (
        <Grid item xs={12} sm={4} key={item.id}>
          <Box
            sx={{
              backgroundColor: "#eee",
              borderRadius: 0.4,
              boxShadow: "0 2px 8px 0 rgb(0 0 0 / 6%)",
              p: 2,
              height: "100%",
            }}
          >
            <Box
              component="img"
              src={item.src}
              alt={item.alt}
              sx={{ width: 40, height: 40, display: "block", margin: "auto" }}
            />
            <Typography
              variant="h5"
              align="center"
              color="textPrimary"
              sx={{
                mt: 2,
              }}
              gutterBottom
            >
              {item.title}
            </Typography>
            <Typography
              variant="body1"
              align="center"
              color="textSecondary"
              paragraph
            >
              {item.content}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default OurServiceCards;
