import React from "react";
import Hero from "../components/Home/Hero";
import Works from "../components/Home/Works";
import { Box } from "@material-ui/core";
import Skills from "../components/Home/Skills";
import WhyChooseUs from "../components/Home/WhyChooseUs";
import OurSoftwares from "../components/Home/OurSoftwares";
import OurTeam from "../components/Home/OurTeam";

const Home = () => {
  return (
    <>
      <Hero />
      <Box
        sx={{
          px: {
            md: 20,
            xs: 2,
          },
          pt: 6,
        }}
      >
        <Works />
        <Skills />
        <WhyChooseUs />
        <OurSoftwares />
        <OurTeam />
      </Box>
    </>
  );
};

export default Home;
