import { Box, Typography } from "@material-ui/core";
import React from "react";

const OurTeam = () => {
  return (
    <Box
      sx={{
        pt: 5,
      }}
    >
      <Typography variant="h1" align="center" color="textPrimary" gutterBottom>
        Our Team
      </Typography>
      <Typography
        variant="p"
        sx={{
          mt: -2,
        }}
        align="center"
        color="textSecondary"
        paragraph
      >
        THE PEOPLE BEHIND THE COMPANY
      </Typography>
      <Box
        sx={{
          p: 6,
          border: "2px solid #000",
        }}
      >
        <Typography
          variant="h5"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Stanley Byrdsell
        </Typography>
        <Typography variant="p" align="center" color="textSecondary" paragraph>
          Solutions Architect
        </Typography>
        <Typography variant="p" align="left" color="textSecondary" paragraph>
          Responsible for the overall design of the applications and mapping the
          business requirements to systems/technical requirements a long with
          Development and Technical Designs.
        </Typography>
      </Box>
    </Box>
  );
};

export default OurTeam;
