import React from "react";
import Hero from "../components/Home/Hero";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    toast.success("Message sent successfully!");
    navigate("/");
  };

  return (
    <>
      <Hero />
      <Box
        sx={{
          px: {
            md: 20,
            xs: 2,
          },
          pt: 6,
        }}
      >
        <Typography
          variant="h1"
          align="center"
          sx={{
            py: 4,
          }}
        >
          Contact Us
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" align="left">
              READY TO GET STARTED?
            </Typography>
            <Typography variant="body1" align="left">
              Complete this form and a D&S National representative will contact
              you.
            </Typography>
            <form autoComplete="off" onSubmit={handleSubmit}>
              <TextField
                id="outlined-basic"
                label="Name"
                name="name"
                variant="outlined"
                required
                fullWidth
                type="text"
                sx={{
                  mt: 2,
                }}
              />
              <TextField
                id="outlined-basic"
                label="Email"
                name="email"
                variant="outlined"
                required
                fullWidth
                sx={{
                  mt: 2,
                }}
                type="email"
              />
              <TextField
                id="outlined-basic"
                label="Phone"
                name="phone"
                variant="outlined"
                required
                fullWidth
                sx={{
                  mt: 2,
                }}
                type="tel"
              />
              <TextField
                id="outlined-basic"
                label="Subject"
                name="subject"
                variant="outlined"
                required
                fullWidth
                sx={{
                  mt: 2,
                }}
                type="text"
              />
              <TextField
                id="outlined-basic"
                label="Message"
                name="message"
                variant="outlined"
                required
                fullWidth
                sx={{
                  mt: 2,
                }}
                type="text"
                multiline
                rows={4}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                  mt: 2,
                }}
              >
                Submit
              </Button>
            </form>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" align="left">
              GET IN TOUCH
            </Typography>
            <Typography variant="body1" align="left">
              <ul>
                <li>Address: P.O Box 1645 Windermere, FL 34786</li>

                <li>Phone: +1 (407)-485-3403</li>

                <li>Fax: +1 (407)-529-7611</li>

                <li>Email: info@ds-national.com</li>

                <li>Skype: ds-national</li>

                <li>MSN: info@ds-national.com</li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Contact;
