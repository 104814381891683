import { Grid, Typography } from "@material-ui/core";
import { CircleOutlined } from "@material-ui/icons";
import React from "react";

const workdata = [
  {
    id: 1,
    title: "APPLICATION LIFECYCLE SERVICES",
    content:
      "D & S National, Inc. draws on over 20 years of applications expertise to offer comprehensive Application Lifecycle Services.",
  },
  {
    id: 2,
    title: "DATABASE ADMINISTRATION",
    content:
      "Provide Application DBA support for both Application Maintenance and Development support for several projects running concurrently.",
  },
  {
    id: 3,
    title: "TECHNOLOGY ARCHITECTURE SERVICES",
    content:
      "Our team of experts guides the building of your IT foundation, with realistic planning, implementation guidance, and a proven software development life cycle",
  },
];

const Works = () => {
  return (
    <>
      <Grid container spacing={3}>
        {workdata.map((work) => (
          <Grid item xs={12} sm={4} key={work.id}>
            <CircleOutlined
              sx={{
                color: "text.secondary",
                fontSize: 60,
                display: "flex",
                alignSelf: "center",
                margin: "auto",
                pb: 1,
              }}
            />
            <Typography
              variant="h5"
              sx={{
                color: "primary.main",
                fontWeight: "bold",
                mb: 2,
              }}
              align="center"
            >
              {work.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
                mb: 2,
              }}
              align="center"
            >
              {work.content}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Works;
