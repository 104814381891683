import React from "react";
import { Box } from "@material-ui/core";
import LogoImg from "../images/DS-AI.png";

const Logo = (props) => (
  <Box
    component="img"
    src={LogoImg}
    alt="DS-AI"
    {...props}
    sx={{
      height: 80,
      // width: "100%",
    }}
  />
);

export default Logo;
