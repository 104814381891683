import PropTypes from "prop-types";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Link,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "../icons/Menu";
import TopBar from "./TopBar";

const MainNavbar = (props) => {
  const { onSidebarMobileOpen } = props;

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "background.dark",
        color: "primary.contrastText",
      }}
      position="static"
    >
      <TopBar />
      <Toolbar sx={{ minHeight: 56 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              md: "none",
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            display: {
              md: "flex",
              xs: "none",
            },
          }}
        >
          <LinkComponent to="/" text="Home" />
          <LinkComponent to="/about" text="About" />
          <LinkComponent to="/contact" text="Contact Us" />
          <LinkComponent to="/services" text="Services" />
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

const LinkComponent = ({ to, text }) => {
  const location = useLocation();
  return (
    <Link
      component={RouterLink}
      to={to}
      underline="none"
      variant="body1"
      sx={{
        color:
          location.pathname === to ? "primary.main" : "primary.contrastText",
        px: 2.5,
        py: 2,
        backgroundColor: location.pathname === to ? "primary.contrastText" : "",
        mx: 1,
      }}
    >
      {text}
    </Link>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
